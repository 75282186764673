import 'animate.css/animate.css';

import { graphql } from 'gatsby';
import React from 'react';

import HomeStory from '../components/homestory';
import Template from '../components/template';

function PartnerTemplate( { data } ) {

	console.log( data.pageData?.edges?.[0]?.node.page_name === 'homestory' );

	return (
		<>
			{
				data.pageData?.edges?.[0]?.node.page_name === 'homestory' ?
					<HomeStory pageData={data.pageData?.edges?.[0]?.node || {}}/>
					:
					<Template pageData={data.pageData?.edges?.[0]?.node || {}}/>
			}</>
	);
};

export default PartnerTemplate;

export const query = graphql`query PageData($page_name: String!){
  pageData: allStrapiPageData(filter: {page_name: {eq: $page_name}}) {
    edges {
      node {
        data_category
        column
        page_name
        content
        page_name
        style_category
        attribute_1_key
        attribute_1_value
        attribute_2_key
        attribute_2_value
        attribute_2_value
        attribute_3_key
        attribute_3_value
        attribute_4_key
        attribute_4_value
        attribute_5_key
        attribute_5_value
        attribute_6_key
        attribute_6_value
      }
    }
  }
}`;
